<template>
  <b-modal
    id="modal-history"
    v-model="open"
    hide-footer
    centered
    size="lg"
    static
    lazy
    no-close-on-esc
    hide-header-close
    :no-close-on-backdrop="true"
    style="max-height: 70vh"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      class="d-flex flex-column"
      variant="transparent"
      style="flex: 1"
    >
      <b-container>
        <h1 class="text-center tw-mb-6">
          {{ $t(title) }}
        </h1>
        <feather-icon
          class="cursor-pointer position-absolute"
          style="top: 0; right: 1rem"
          icon="XIcon"
          size="22"
          @click="close"
        />
        <b-table
          :busy="loading"
          :items="pageItems"
          responsive
          :fields="tableColumns"
          class="table-header mb-0 mt-2"
        >
          <template #head()="{ label }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ $t(label) }}</span>
            </div>
          </template>
          <template #cell(timestamp)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ new Date(item.timestamp).toLocaleString('en-US') }}</span>
            </div>
          </template>
          <template #cell(name)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.interpretation.name }}</span>
            </div>
          </template>
          <template #cell(action)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.interpretation.action }}</span>
            </div>
          </template>
          <template #cell(text)="{ item }">
            <div>
              <span>{{ item.interpretation.text }}</span>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="allItemsCount"
          :per-page="size"
          first-number
          align="end"
          last-number
          class="mt-2 align-self-end"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
            />
          </template>
        </b-pagination>
        <div class="d-flex justify-content-end align-items-center tw-mt-6">
          <button
            class="btn btn-light tw-ml-3"
            type="button"
            @click="close"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import RootEvents from '@/constants/rootEvents'
import GlobalToastNotification from '@/components/shared/GlobalToastNotification.vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import dayjs from 'dayjs'
import { customEntityRowsService } from '@/services/customEntityData'

export default {
  components: {
    vSelect,
    DatePicker,
  },
  data() {
    return {
      dayjs,
      title: 'History',
      tableColumns: [
        { key: 'timestamp', label: 'Date' },
        { key: 'name', label: 'Name' },
        { key: 'action', label: 'Cause' },
        { key: 'text', label: 'Summary' },
      ],
      uuid_key: null,
      open: false,
      loading: false,
      page: 1,
      size: 10,
      tableName: null,
      allItemsCount: 0,
      filteredItemsCount: 0,
      pageItems: [],
    }
  },
  computed: {
    ...mapGetters({
      searchModel: 'advancedSearch/searchModel',
    }),

    isEditMode() {
      return !!this.$route.params.id
    },

    canSavePersonal() {
      return this.$can('Create', 'Personal_Query')
    },
  },
  watch: {
    page: {
      handler(oldPage, newPage) {
        if (oldPage === newPage || !newPage) return
        this.getHistory()
      },
      immediate: true,
    },
  },

  destroyed() {
    this.$root.$off(RootEvents.ADV_SEARCH_CONFIRM_SAVE)
  },

  methods: {
    close() {
      this.open = false
      this.loading = false
      this.uuid_key = null
      this.page = 1
      this.tableName = null
      this.allItemsCount = 0
      this.filteredItemsCount = 0
      this.pageItems = []
    },
    async getHistory() {
      const { id, tableName } = this.$route.params
      if (!id || !tableName || !this.uuid_key) return
      this.loading = true

      try {
        const res = await customEntityRowsService(tableName).getHistory(tableName, this.uuid_key, {
          page: this.page - 1,
          size: this.size,
          q: this.query,
        })

        if (!res.data) return

        this.pageItems = res.data.pageItems
        this.allItemsCount = res.data.allItemsCount
        this.loading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async openModal({ item }) {
      if (!item || !item.uuid_key) return
      this.open = true
      this.uuid_key = item.uuid_key
      this.getHistory()
    },
  },
}
</script>
